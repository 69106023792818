import React from "react"

const Continual = () => (
    <svg width="240" height="218" viewBox="0 0 240 218" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a bar graph. A hand is pushing the highest bar in the chart even higher.</desc>
        <path d="M38.8001 8.80004C51.0001 -1.89996 70.7001 -2.69996 83.7001 7.00004C91.9001 13.1 97.0001 22.3 102.6 30.8C108.2 39.3 115.2 47.7 124.9 50.8C137.2 54.6 150.2 48.8 161.5 42.7C172.8 36.6 185.1 29.9 197.8 32C206.8 33.5 214.6 39.3 220.1 46.5C225.6 53.7 229.1 62.3 232 70.8C238.3 89.5 242.3 110 236.5 128.8C230.6 147.6 212.2 163.6 192.6 161C186.8 160.2 181.4 157.9 175.7 156.6C170 155.3 163.7 155.1 158.8 158.2C153.4 161.6 150.9 168.3 150.1 174.7C149.2 181.1 149.5 187.6 148 193.8C144.5 207.4 131.1 217.8 117.1 217.9C103 217.9 89.6001 207.6 86.1001 193.9C84.7001 188.5 84.7001 182.9 83.6001 177.5C82.5001 172.1 79.8001 166.5 74.9001 164C67.1001 160 58.1001 165.1 49.6001 167.4C34.4001 171.5 17.0001 165.3 7.90011 152.5C-1.19989 139.7 -1.39988 121.2 7.50012 108.2C11.8001 101.9 17.9001 97.0001 23.4001 91.7001C28.9001 86.4001 34.2001 80.3 36.0001 72.8C38.8001 61.3 29.2001 52.4 27.6001 41.4C25.7001 29.5 29.7001 16.7 38.8001 8.80004Z" fill="#eff1f6"/>
        <path d="M190.3 14.1V72.1C190.1 72.1 189.8 72.1 189.6 72.2C188.3 72.5 187.6 73.6 187.2 74.8C187.3 73.1 187.2 71.4 187 69.7C186.8 67.1 186.5 63.9 184.9 61.7C183 59.2 178.8 59.8 177.4 62.5C177 63.2 176.8 63.9 176.7 64.7C176.7 60.5 175.8 55.4 171.7 55.4C164.8 55.4 165.3 65.6 165.3 69.1C165.3 69.1 163.5 66.6 161.3 65.6C159.7 64.9 157.7 65.1 156 67.4V14.1C156 12.2 157.6 10.6 159.5 10.6H186.8C188.7 10.6 190.3 12.2 190.3 14.1Z" fill="#61D0EC"/>
        <path d="M165.2 69.1001C165.2 69.1001 165.1 76.6 165 84.8H161.7C159.7 85.7 157.3 85.8001 155.2 85.1001L152.1 84.8C152.5 80.3 153.1 75.8001 154 72.0001C154.5 70.0001 155.1 68.5001 155.9 67.5001C157.5 65.2001 159.6 65.0001 161.2 65.7001C163.4 66.6001 165.2 69.1001 165.2 69.1001Z" fill="white"/>
        <path d="M187 69.7C187.2 71.4 187.2 73.1 187.2 74.8V82.5H184.2C182.5 83.1 180.6 83 179.1 82.2H176.3V70C176.3 70 176.3 70 176.3 69.9C176.4 69.4 176.7 67.2 176.6 64.7C176.7 63.9 177 63.2 177.3 62.5C178.7 59.8 182.9 59.2 184.8 61.7C186.6 63.9 186.8 67 187 69.7Z" fill="white"/>
        <path d="M182.9 166.8C182.9 166.1 182.4 165.6 181.7 165.6C181 165.6 180.5 166.1 180.5 166.8C180.5 167.5 181 168 181.7 168C182.4 168 182.9 167.5 182.9 166.8ZM181.7 163.2C182.4 163.2 182.9 162.7 182.9 162C182.9 161.3 182.4 160.8 181.7 160.8C181 160.8 180.5 161.3 180.5 162C180.5 162.7 181.1 163.2 181.7 163.2ZM194.2 157.9V177.8H153.1V157.9H155.6H185.4H191.4H194.2Z" fill="#61D0EC"/>
        <path d="M165 84.8C165.1 76.6 165.2 69.1 165.2 69.1C165.2 65.6 164.7 55.4 171.6 55.4C175.7 55.4 176.6 60.6 176.6 64.7C176.4 65.8 176.3 66.8 176.3 67.9C176.3 68.6 176.3 69.3 176.3 69.9C176.3 70 176.3 70 176.3 70V82.2H179.1C180.7 83 182.6 83.1 184.2 82.5H187.2V74.8C187.6 73.6 188.3 72.5 189.6 72.2C189.8 72.1 190.1 72.1 190.3 72.1C192.4 72 194.6 73.8 195.5 75.6C196.2 76.9 196.4 78.3 196.6 79.8C197 83.8 196.8 87.8 197.2 91.9C197.4 94.1 197.7 96.3 198 98.4C198.3 100.4 198.7 102.3 199.1 104.3C200.4 110.7 201 117.2 201.6 123.7C202.4 134.7 198.8 146.1 192.9 155.8C192.5 156.5 192 157.3 191.5 158H185.5H155.7C155.3 156.2 154.8 154.5 154.1 152.8C152.9 150 151.1 147.2 148.9 145C148 144.1 146.8 143.3 145.5 142.4C143.3 140.8 140.8 139.2 139.8 137.3C138.5 135 137.7 132.5 136.6 130.1C135.5 127.7 133.7 125.8 132.3 123.6C129.8 119.7 127 114.8 127.5 110C128 105.3 132.2 104 135.7 106.9C139.1 109.6 142.7 113.9 145.5 117.5C148.2 121 150 123.8 150 123.8L151.2 108.8C151.4 106 151.3 95.2 152.3 84.7L155.4 85C157.5 85.7 159.8 85.7 161.9 84.7L165 84.8Z" fill="white"/>
        <path d="M145.5 142.4V157.7C145.5 159.6 143.9 161.2 142 161.2H114.7C112.8 161.2 111.2 159.6 111.2 157.7V43C111.2 41.1 112.8 39.5 114.7 39.5H142C143.9 39.5 145.5 41.1 145.5 43V117.5C142.7 113.9 139.1 109.6 135.7 106.9C132.2 104.1 128 105.3 127.5 110C127 114.8 129.8 119.7 132.3 123.6C133.7 125.8 135.4 127.7 136.6 130.1C137.7 132.5 138.5 135 139.8 137.3C140.8 139.1 143.2 140.8 145.5 142.4Z" fill="white"/>
        <path d="M100.6 71.9V157.6C100.6 159.5 99.0002 161.1 97.1002 161.1H69.8002C67.9002 161.1 66.3002 159.5 66.3002 157.6V71.9C66.3002 70 67.9002 68.4 69.8002 68.4H97.1002C99.0002 68.4 100.6 70 100.6 71.9Z" fill="white"/>
        <path d="M55.7002 100.8V157.6C55.7002 159.5 54.1002 161.1 52.2002 161.1H24.9001C23.0001 161.1 21.4001 159.5 21.4001 157.6V100.8C21.4001 98.9 23.0001 97.3 24.9001 97.3H52.2002C54.2002 97.3 55.7002 98.9 55.7002 100.8Z" fill="white"/>
        <path d="M182.9 162C182.9 162.7 182.4 163.2 181.7 163.2C181 163.2 180.5 162.7 180.5 162C180.5 161.3 181 160.8 181.7 160.8C182.4 160.8 182.9 161.3 182.9 162Z" fill="#272223"/>
        <path d="M181.7 165.6C182.4 165.6 182.9 166.1 182.9 166.8C182.9 167.5 182.4 168 181.7 168C181 168 180.5 167.5 180.5 166.8C180.5 166.1 181.1 165.6 181.7 165.6Z" fill="#272223"/>
        <path d="M52.2002 97.3H24.9001C23.0001 97.3 21.4001 98.9 21.4001 100.8V157.6C21.4001 159.5 23.0001 161.1 24.9001 161.1H52.2002C54.1002 161.1 55.7002 159.5 55.7002 157.6V100.8C55.7002 98.9 54.2002 97.3 52.2002 97.3Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M97.1002 68.4H69.8002C67.9002 68.4 66.3002 70 66.3002 71.9V157.6C66.3002 159.5 67.9002 161.1 69.8002 161.1H97.1002C99.0002 161.1 100.6 159.5 100.6 157.6V71.9C100.6 70 99.0002 68.4 97.1002 68.4Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.5 142.4V157.7C145.5 159.6 143.9 161.2 142 161.2H114.7C112.8 161.2 111.2 159.6 111.2 157.7V43C111.2 41.1 112.8 39.5 114.7 39.5H142C143.9 39.5 145.5 41.1 145.5 43V117.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156 67.4V14.1C156 12.2 157.6 10.6 159.5 10.6H186.8C188.7 10.6 190.3 12.2 190.3 14.1V72.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.7 157.9C155.3 156.1 154.8 154.4 154.1 152.7C152.9 149.9 151.1 147.1 148.9 144.9C148 144 146.8 143.2 145.5 142.3C143.3 140.7 140.8 139.1 139.8 137.2C138.5 134.9 137.7 132.4 136.6 130C135.5 127.6 133.7 125.7 132.3 123.5C129.8 119.6 127 114.7 127.5 109.9C128 105.2 132.2 103.9 135.7 106.8C139.1 109.5 142.7 113.8 145.5 117.4C148.2 120.9 150 123.7 150 123.7L151.2 108.7C151.4 105.9 151.3 95.1001 152.3 84.6001C152.7 80.1001 153.3 75.6 154.2 71.8C154.7 69.8 155.3 68.3 156.1 67.3C157.7 65 159.8 64.8 161.4 65.5C163.5 66.5 165.4 69 165.4 69C165.4 65.5 164.9 55.3 171.8 55.3C175.9 55.3 176.8 60.5001 176.8 64.6001C176.8 67.1001 176.5 69.3 176.5 69.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.3 70C176.3 70 176.3 70 176.3 69.9C176.4 69.2 176.3 68.5 176.3 67.9C176.3 66.9 176.4 65.8 176.6 64.7C176.7 63.9 177 63.2 177.3 62.5C178.7 59.8 182.9 59.2 184.8 61.7C186.5 63.9 186.7 67 186.9 69.7C187.1 71.4 187.1 73.1 187.1 74.8C187.5 73.6 188.2 72.5 189.5 72.2C189.7 72.1 190 72.1 190.2 72.1C192.3 72 194.5 73.8 195.4 75.6C196.1 76.9 196.3 78.3 196.5 79.8C196.9 83.8 196.7 87.8 197.1 91.9C197.3 94.1 197.6 96.3 197.9 98.4C198.2 100.4 198.6 102.3 199 104.3C200.3 110.7 200.9 117.2 201.5 123.7C202.3 134.7 198.7 146.1 192.8 155.8C192.4 156.5 191.9 157.3 191.4 158" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.2 74.8V82.5001V107.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.3 70V82.2V103" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.2 69.1C165.2 69.1 165.1 76.6 165 84.8C164.9 92.3 164.8 100.5 164.8 104.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M191.4 157.9H194.2V177.8H153.1V157.9H155.7H185.4H191.4Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M136.2 122.5C137.9 122.3 139.4 121.3 140.3 119.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.4 120C143.7 122.9 142.6 125.9 140.5 128" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156.4 88.5C157.6 88.9 158.8 88.9 160 88.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M161.7 84.7001C159.7 85.6001 157.3 85.7001 155.2 85.0001" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M191.2 94.7001C191.9 94.9001 192.7 95 193.5 94.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M184.2 82.5001C182.5 83.1001 180.6 83.0001 179.1 82.2001" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M179.1 85.4C180.4 85.9 181.9 86 183.3 85.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.6 79.9C170 80.4 171.5 80.4 172.9 80.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M185.4 157.9V169.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M201.6 187.6C201.6 185.9 201.8 184.1 201.8 182.4V167.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.7 179.3C206.7 178.9 206.7 178.6 206.7 178.3V157.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.6 191.3C187.7 191.2 187.7 191.2 187.8 191.1V181.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M146.7 164.5V177.4" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.6 189.6V183" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.7 184.6V199.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.7 144.6V122.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.8002 115C26.8002 124.2 26.8002 133.4 26.7002 142.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M93.7001 81.5001C93.6001 82.2001 93.6001 82.8001 93.5001 83.5001V129" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M118.1 91.7001V61.0001" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Continual