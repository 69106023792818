import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop"
import ArrowDown from "../components/svg/arrowDown"
import ChartMan from "../components/svg/chartMan"
import Complex from "../components/svg/complex"
import World from "../components/svg/world"
import Continual from "../components/svg/continual"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const ExistPage = () => (

  	<Layout>

      <Seo 
         title="Why we exist | data-based media analysis" 
         description="Madclarity provides data-based media analysis to determine, and improve, media and advertising performance."
      />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="400"
                     >Why we <span className="blue">exist</span></h1>
                     <p
                        data-sal="slide-up" 
                        data-sal-delay="300"
                        data-sal-duration="400"
                     >We're here to help marketers learn, to challenge their media status quo and to achieve better media and advertising outcomes.</p>
                     <div className="btn-row"
                        data-sal="slide-up" 
                        data-sal-delay="500"
                        data-sal-duration="400"
                     >
                        <button onClick={() => scrollTo('#anchor')} className="btn-scroll">  
                            <ArrowDown />    
                        </button>
                     </div>
                  </div>
                  <div className="sub-hero-img">
                     <ChartMan />
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad" id="anchor">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Complex />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h2><span className="blue">Clarity</span> from Complexity</h2>
                        <p className="lead">We have seen a decline in transparency and integrity in the advertising media business ... a decay in experience and smarts ... and marketers relying on KPIs which have a negative effect on their business performance.</p>
                        <p className="lead">We created Madclarity to help our clients reverse this crazy trend and drive continual improvement for their business.</p>
                        <p className="lead">We create clarity from complexity.</p>
                        <p className="lead">Madclarity is a totally <strong>independent advertising media consultancy</strong> and supports that independence, experience and clarity with deep, data-based analysis of our clients’ performance.</p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section>
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h3>Real World <span className="blue">Experience</span></h3>
                        <p className="lead">The Partners of Madclarity have worked with well over 100 leading advertisers in Australia, NZ and SE Asia.</p>
                        <p className="lead">At Faulkner Media Management they developed the first independent media consultancy and the <strong>first software-driven media benchmarking tool</strong> in the world (outside the UK) and the most lauded media training course workshop anywhere.</p>
                        <p className="lead">Madclarity has built on that experience and knowledge, enabling us to become trusted advisors to our clients ... and help them in a unique and measurable way.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <World />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Continual />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h4>Continual <span className="blue">Improvement</span></h4>
                        <p className="lead">Madclarity combines that experience with the support of our unique software and a philosophy of listening, to find the right consultative ways to help each of our clients deliver better business outcomes.</p>
                        <p className="lead">Our rapid growth at Madclarity comes from continual learning, challenging and improving and helping our clients do that too.</p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <div className="container section-pad-btm">
         <div className="row">
            <div className="col-middle-wrapper">
               <div className="col-middle">
                  <Link to="/how-we-help" className="btn btn-lg btn-middle">How we help</Link>
               </div> 
            </div>
         </div>
      </div>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                     <Quotes />
                     <div className="test-excerpt">
                        ... highly value their
                        <span className="blue"> insights</span> ...
                     </div>
                     <Testimonial
                        quote="I have worked with Eric Faulkner and his team at FMM and Madclarity for 20 years. I highly value their insights and counsel on strategy."
                        name="Clynton Bartholomeusz"
                        title="Managing Director - Beiersdorf Australia & New Zealand"
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>
      
      <Cta />

  </Layout>
)

export default ExistPage