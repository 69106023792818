import React from "react"

const World = () => (
    <svg width="252" height="230" viewBox="0 0 252 230" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>An illustration of a globe</desc>
        <path d="M40.4001 9.39998C53.2001 -1.90002 74.0001 -2.70001 87.7001 7.49999C96.3001 13.9 101.7 23.6 107.6 32.6C113.5 41.6 120.9 50.5 131.1 53.6C144 57.6 157.7 51.4 169.7 45C181.6 38.6 194.5 31.5 207.9 33.7C217.3 35.2 225.6 41.3 231.4 48.9C237.2 56.5 240.8 65.5 243.9 74.6C250.6 94.3 254.8 115.9 248.6 135.7C242.4 155.5 223 172.4 202.4 169.6C196.3 168.8 190.6 166.4 184.6 165C178.6 163.6 172 163.4 166.8 166.7C161.1 170.3 158.5 177.4 157.6 184.1C156.7 190.8 157 197.7 155.3 204.2C151.6 218.6 137.5 229.5 122.7 229.6C107.9 229.6 93.7001 218.7 90.0001 204.4C88.5001 198.8 88.6001 192.8 87.4001 187.1C86.2001 181.4 83.4001 175.5 78.3001 172.9C70.1001 168.7 60.6001 174.1 51.7001 176.5C35.7001 180.8 17.3001 174.3 7.70008 160.7C-1.89992 147.1 -2.09994 127.7 7.30006 114C11.9001 107.3 18.3001 102.2 24.1001 96.6C29.9001 91 35.4001 84.5 37.3001 76.7C40.2001 64.6 30.1001 55.2 28.4001 43.6C26.6001 31.2 30.9001 17.8 40.4001 9.39998Z" fill="#eff1f6"/>
        <path d="M193.6 84.7999C193.6 91.2999 192.8 97.7 191.1 103.7C190 104.2 188.8 104.6 187.6 104.9C180.4 106.5 172 103.2 168.8 96.5C165.9 90.2 167.2 81.2 161.2 77.6C158.5 76 154.8 75.8999 153 73.2999C150.9 70.2999 152.9 66.3 155.2 63.5C157.5 60.7 160.4 57.7 160 54.1C159.7 50.6 156.3 48.1 153 46.8C149.7 45.5 146 44.9 143.2 42.7C138.3 38.9 137.7 31.4 140.1 25.6C141.6 22 144 19 146.7 16.2C174.3 27 193.6 53.5999 193.6 84.7999Z" fill="white"/>
        <path d="M187.7 104.8C188.9 104.5 190.1 104.1 191.2 103.6C182.9 135.1 154.2 158.3 120.1 158.3C86.3997 158.3 57.8997 135.6 49.1997 104.6C50.0997 103.6 50.6997 102.4 50.9997 101C51.9997 96.5 50.9997 91.4 53.5997 87.6C56.3997 83.6 61.4997 84.5 65.0997 87.9C68.6997 91.3 70.5997 96.1 73.8997 99.8C77.1997 103.5 83.2997 105.7 86.9997 102.4C91.1997 98.6 88.5997 91.6 85.0997 87.1C81.5997 82.6 77.2997 77.3 79.1997 71.9C81.5997 65.1 91.0997 65.1 97.1997 61.4C102.5 58.1 105.1 52 107.4 46.2C110.1 39.5 112.8 32.6 113.2 25.4C113.5 20.7 112.8 15.9 111.3 11.4C114.2 11.1 117.1 10.9 120 10.9C129.5 10.9 138.5 12.7 146.8 15.9C144.1 18.7 141.7 21.7 140.2 25.3C137.8 31 138.4 38.5 143.3 42.4C146.1 44.6 149.7 45.2 153.1 46.5C156.4 47.8 159.8 50.2 160.1 53.8C160.4 57.4 157.6 60.4 155.3 63.2C153 66 151 70 153.1 73C154.9 75.6 158.6 75.7 161.3 77.3C167.3 80.9 165.9 89.9 168.9 96.2C172.1 103.2 180.5 106.5 187.7 104.8ZM153.8 135.5C154.1 133.5 154.2 131.3 153.1 129.6C151.6 127.4 148.2 127 145.9 128.4C141.7 130.9 139.7 140.4 143.6 144C149 148.9 153.2 139.7 153.8 135.5ZM133.3 128.5C133.9 125 132.7 121.5 130.7 118.6C128.7 115.7 126 113.4 123.3 111.2C121.1 109.3 118.8 107.4 116 106.4C113.3 105.3 110 105.3 107.6 107C103.9 109.6 103.8 115 101.3 118.7C99.5997 121.2 96.9997 122.9 94.4997 124.5C91.9997 126.1 89.3997 127.9 87.8997 130.5C86.3997 133.1 86.4997 136.8 88.7997 138.8C90.8997 140.5 94.0997 140.2 96.5997 139.3C99.1997 138.3 101.6 136.9 104.3 136.4C108.2 135.8 108.7 138.6 111.7 139.6C115 140.7 121 139.3 124.1 138.1C128.4 136.5 132.4 133.1 133.3 128.5ZM105.1 85.4C108 83.4 109.5 78.9 109.3 75.6C109.1 73.2 108.1 70.7 106 69.6C103.3 68.2 99.7997 69.5 98.0997 72C96.4997 74.5 96.4997 77.9 97.5997 80.7C98.9997 84.4 101.1 88 105.1 85.4Z" fill="#61D0EC"/>
        <path d="M111.3 11.7C112.7 16.2 113.5 20.9 113.2 25.7C112.8 32.9 110 39.7 107.4 46.5C105.1 52.3 102.4 58.4 97.1998 61.7C91.0998 65.5 81.5998 65.5 79.1998 72.2C77.2998 77.6 81.5998 82.8999 85.0998 87.3999C88.5998 91.8999 91.1998 98.9 86.9998 102.7C83.2998 106 77.1998 103.8 73.8998 100.1C70.5998 96.4 68.6998 91.6 65.0998 88.2C61.4998 84.8 56.4998 83.7999 53.5998 87.8999C50.9998 91.6999 51.9998 96.6999 50.9998 101.3C50.6998 102.7 50.0998 103.9 49.1998 104.9C47.3998 98.5999 46.4998 91.9 46.4998 85C46.3998 47.1 74.7998 16 111.3 11.7Z" fill="white"/>
        <path d="M109.3 75.5999C109.5 78.8999 108 83.3999 105.1 85.3999C101.2 87.9999 99.0998 84.4 97.5998 80.7C96.4998 77.9 96.4998 74.4999 98.0998 71.9999C99.6998 69.4999 103.3 68.0999 106 69.5999C108.1 70.6999 109.1 73.1999 109.3 75.5999Z" fill="white"/>
        <path d="M153.1 129.6C154.2 131.3 154.1 133.5 153.8 135.5C153.2 139.6 149 148.9 143.6 143.9C139.7 140.3 141.7 130.8 145.9 128.3C148.2 127 151.6 127.3 153.1 129.6Z" fill="white"/>
        <path d="M130.6 118.6C132.6 121.5 133.9 125 133.2 128.5C132.4 133.1 128.4 136.5 124 138.1C120.8 139.2 114.9 140.7 111.6 139.6C108.7 138.6 108.1 135.8 104.2 136.4C101.5 136.8 99.0999 138.3 96.4999 139.3C93.8999 140.3 90.7999 140.6 88.6999 138.8C86.3999 136.9 86.2999 133.2 87.7999 130.5C89.2999 127.9 91.7999 126.1 94.3999 124.5C96.8999 122.9 99.5999 121.2 101.2 118.7C103.7 115 103.8 109.6 107.5 107C109.9 105.3 113.2 105.3 115.9 106.4C118.6 107.4 120.9 109.3 123.2 111.2C125.9 113.4 128.6 115.7 130.6 118.6Z" fill="white"/>
        <path d="M194 139.8C194 139.8 199.7 134.6 206 126.6C208.7 123.2 210.2 124 211.2 124.9C212.6 126.1 212.6 128.8 210.2 134.6C206.1 144.6 196.3 161.1 194 166C191.7 171 180.5 176.1 172.4 180.4C167.2 183.2 163.9 187 162.2 192.1H143.5H137C138.5 171.5 145.5 164.3 154.1 162.6C162.5 160.9 171.3 156.6 178.8 149.1C182.1 145.8 185.3 142.5 188 141.1C191.4 139.3 194 139.8 194 139.8Z" fill="white"/>
        <path d="M47.9998 141.1C50.6998 142.5 53.8998 145.8 57.1998 149.1C64.6998 156.7 73.3998 160.9 81.8998 162.6C90.3998 164.3 97.4998 171.6 98.9998 192.1H92.3998H73.6998C71.9998 187 68.6998 183.2 63.4998 180.4C55.3998 176.1 44.1998 170.9 41.8998 166C39.5998 161 29.8998 144.6 25.6998 134.6C23.2998 128.8 23.3998 126 24.6998 124.9C25.6998 124 27.1998 123.2 29.8998 126.6C36.1998 134.6 41.8998 139.8 41.8998 139.8C41.8998 139.8 44.4998 139.3 47.9998 141.1Z" fill="white"/>
        <path d="M95.3998 198.7C94.7998 198.7 94.3998 199.2 94.3998 199.7C94.3998 200.2 94.8998 200.7 95.3998 200.7C95.8998 200.7 96.3998 200.2 96.3998 199.7C96.3998 199.2 95.9998 198.7 95.3998 198.7ZM95.3998 196.7C95.9998 196.7 96.3998 196.2 96.3998 195.7C96.3998 195.1 95.8998 194.7 95.3998 194.7C94.8998 194.7 94.3998 195.2 94.3998 195.7C94.3998 196.2 94.8998 196.7 95.3998 196.7ZM102.5 192.1V208.9H70.5999V192.1H73.6999H92.3998H98.9998H102.5Z" fill="#61D0EC"/>
        <path d="M141.5 199.8C141.5 199.2 141 198.8 140.5 198.8C139.9 198.8 139.5 199.3 139.5 199.8C139.5 200.3 140 200.8 140.5 200.8C141 200.8 141.5 200.3 141.5 199.8ZM140.4 196.7C141 196.7 141.4 196.2 141.4 195.7C141.4 195.1 140.9 194.7 140.4 194.7C139.8 194.7 139.4 195.2 139.4 195.7C139.4 196.2 139.9 196.7 140.4 196.7ZM133.4 192.1H137H143.6H162.3H165.4V208.9H133.5V192.1H133.4Z" fill="#61D0EC"/>
        <path d="M140.4 194.6C141 194.6 141.4 195.1 141.4 195.6C141.4 196.2 140.9 196.6 140.4 196.6C139.8 196.6 139.4 196.1 139.4 195.6C139.4 195.1 139.9 194.6 140.4 194.6Z" fill="#272223"/>
        <path d="M140.4 198.7C141 198.7 141.4 199.2 141.4 199.7C141.4 200.2 140.9 200.7 140.4 200.7C139.8 200.7 139.4 200.2 139.4 199.7C139.4 199.2 139.9 198.7 140.4 198.7Z" fill="#272223"/>
        <path d="M95.3999 194.6C95.9999 194.6 96.3999 195.1 96.3999 195.6C96.3999 196.2 95.8999 196.6 95.3999 196.6C94.8999 196.6 94.3999 196.1 94.3999 195.6C94.3999 195.1 94.8999 194.6 95.3999 194.6Z" fill="#272223"/>
        <path d="M95.3999 198.7C95.9999 198.7 96.3999 199.2 96.3999 199.7C96.3999 200.2 95.8999 200.7 95.3999 200.7C94.8999 200.7 94.3999 200.2 94.3999 199.7C94.3999 199.2 94.8999 198.7 95.3999 198.7Z" fill="#272223"/>
        <path d="M191.2 103.7C192.8 97.7 193.7 91.2999 193.7 84.7999C193.7 53.5999 174.3 27 146.9 16.2C138.6 12.9 129.5 11.2 120.1 11.2C117.1 11.2 114.2 11.4 111.4 11.7C74.9 16 46.5 47.0999 46.5 84.7999C46.5 91.6999 47.4 98.4 49.2 104.7C57.9 135.7 86.3 158.4 120.1 158.4C154.2 158.4 182.9 135.2 191.2 103.7Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M146.9 16.2C144.2 19 141.8 22 140.3 25.6C137.9 31.3 138.5 38.8 143.4 42.7C146.2 44.9 149.8 45.5 153.2 46.8C156.5 48.1 159.9 50.5 160.2 54.1C160.5 57.7 157.7 60.7 155.4 63.5C153.1 66.3 151.1 70.2999 153.2 73.2999C155 75.8999 158.7 76 161.4 77.6C167.4 81.2 166 90.2 169 96.5C172.1 103.2 180.5 106.5 187.8 104.9C189 104.6 190.2 104.2 191.3 103.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M111.3 11.7C112.7 16.2 113.5 20.9 113.2 25.7C112.8 32.9 110 39.7 107.4 46.5C105.1 52.3 102.4 58.4 97.2 61.7C91.1 65.5 81.6 65.5 79.2 72.2C77.3 77.6 81.5999 82.8999 85.0999 87.3999C88.5999 91.8999 91.1999 98.9 86.9999 102.7C83.2999 106 77.1999 103.8 73.8999 100.1C70.5999 96.4 68.6999 91.6 65.0999 88.2C61.4999 84.8 56.4999 83.7999 53.5999 87.8999C50.9999 91.6999 51.9999 96.6999 50.9999 101.3C50.6999 102.7 50.1 103.9 49.2 104.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M97.5998 80.7C96.4998 77.9 96.4998 74.4999 98.0998 71.9999C99.6998 69.4999 103.3 68.0999 106 69.5999C108.1 70.7999 109.1 73.2999 109.3 75.5999C109.5 78.8999 108 83.3999 105.1 85.3999C101.1 87.9999 98.9998 84.4 97.5998 80.7Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M104.3 136.4C101.6 136.8 99.2 138.3 96.6 139.3C94 140.3 90.9 140.6 88.8 138.8C86.5 136.9 86.4 133.2 87.9 130.5C89.4 127.9 91.9 126.1 94.5 124.5C97 122.9 99.7 121.2 101.3 118.7C103.8 115 103.9 109.6 107.6 107C110 105.3 113.3 105.3 116 106.4C118.7 107.4 121 109.3 123.3 111.2C126 113.4 128.7 115.7 130.7 118.6C132.7 121.5 134 125 133.3 128.5C132.5 133.1 128.5 136.5 124.1 138.1C120.9 139.2 115 140.7 111.7 139.6C108.7 138.6 108.2 135.8 104.3 136.4Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.9 128.3C148.2 126.9 151.6 127.3 153.1 129.5C154.2 131.2 154.1 133.4 153.8 135.4C153.2 139.5 149 148.8 143.6 143.8C139.7 140.3 141.7 130.8 145.9 128.3Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M66.5 168C58.5 164 49.7999 156 43.7999 149.1C37.6999 142.2 41.8999 139.7 41.8999 139.7C41.8999 139.7 36.1999 134.5 29.8999 126.5C27.1999 123.1 25.7 123.9 24.7 124.8C23.3 126 23.3 128.7 25.7 134.5C29.8 144.5 39.5999 161 41.8999 165.9C44.1999 170.9 55.4 176 63.5 180.3C68.7 183.1 72 186.9 73.7 192" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.8999 139.8C41.8999 139.8 44.4999 139.3 47.9999 141.1C50.6999 142.5 53.8999 145.8 57.1999 149.1C64.6999 156.7 73.3999 160.9 81.8999 162.6C90.3999 164.3 97.4999 171.6 98.9999 192.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.6 192.1H70.5V208.9H102.5V192.1H98.9H92.3H73.6Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53.2 153.6C54 153.6 54.7999 153 55.0999 152.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M46.6 147.3C46 146.8 45.5 146.2 45.5 145.4C45.5 144.7 45.9 143.9 46.7 143.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M54 156.5C54.9 156.4 55.8 155.9 56.4 155.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.2 186.9C92.4 180.7 89 174.5 83.7 171.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M61.7998 174.4C57.4998 172.6 53.2999 170.2 49.5999 167.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.2998 192.1V201.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.3 192.1C164 187 167.3 183.2 172.5 180.4C180.6 176.1 191.8 170.9 194.1 166C196.4 161 206.1 144.6 210.3 134.6C212.7 128.8 212.6 126 211.3 124.9C210.3 124 208.8 123.2 206.1 126.6C199.8 134.6 194.1 139.8 194.1 139.8C194.1 139.8 198.2 142.3 192.2 149.2C186.1 156.1 177.5 164 169.5 168.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M137 192.1C138.5 171.5 145.5 164.3 154.1 162.6C162.5 160.9 171.3 156.6 178.8 149.1C182.1 145.8 185.3 142.5 188 141.1C191.5 139.3 194.1 139.8 194.1 139.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.3 192.1H165.3V208.9H133.4V192.1H137H143.6H162.3Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M182.7 153.6C181.9 153.6 181.1 153 180.8 152.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M189.2 147.3C189.8 146.8 190.3 146.2 190.3 145.4C190.3 144.7 189.9 143.9 189.1 143.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M181.8 156.5C180.9 156.4 180 155.9 179.4 155.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.6 186.9C143.4 180.7 146.8 174.5 152.1 171.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.1 174.4C178.4 172.6 182.6 170.2 186.3 167.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.6 192.1V201.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M74.1002 79C70.8002 76.9 70.4002 72 72.2002 68.6C73.9002 65.1 77.2002 62.7 80.2002 60.3C83.2002 57.9 86.4002 55.2 87.6002 51.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.7 57.4C167.2 59.3 165.8 61.2 164.3 63.1C163.3 64.3 162.4 66 163.2 67.3C163.7 68.1 164.9 68.4 165.8 68.4C166.8 68.4 167.8 68.1 168.8 68.1C171.3 68.2 173.4 70.1 174.3 72.4C175.2 74.7 175.3 77.2 175.4 79.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M118.5 133.4C120.9 133.2 123.1 131.5 123.7 129.2C124.4 126.9 123.5 124.3 121.6 122.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M101.5 79.2C100.2 77.8 100.7 75.1 102.4 74.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M146.6 139.6C148.6 138.7 149.2 135.8 147.8 134.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)

export default World